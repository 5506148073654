import instance from "./axios.base";

const getEmergencyContactInfo = async (id) => {
  const res = await instance.get(`/empempgencycontact/${id}`);
  return res.data;
};

const editEmergencyContactInfo = async (data) => {
  await instance.put("/empempgencycontact", data);
};

const deleteEmergencyContactInfo = async (id) => {
  await instance.delete(`/empempgencycontact/${id}`);
};

export {
  getEmergencyContactInfo,
  editEmergencyContactInfo,
  deleteEmergencyContactInfo,
};
