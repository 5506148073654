import instance from "./axios.base";

const getProfiles = async () => {
  const res = await instance.get("/profile");
  return res.data;
};

const getProfile = async (id) => {
  const res = await instance.get(`/empemployees/profile/${id}`);
  return res.data;
};

const createProfile = async (data) => {
  const res = await instance.post("/profile", data);

  return res.data;
};

const updateProfile = async (data) => {
  const res = await instance.put("/profile", data);

  return res.data;
};

const updatePassword = async (data) => {
  const res = await instance.put("/changepassword", data);

  return res.data;
};

const removeProfile = async (data) => {
  const res = await instance.delete(`/profile/${data.id}`);

  return res.data;
};

export {
  getProfiles,
  getProfile,
  createProfile,
  updateProfile,
  removeProfile,
  updatePassword,
};
