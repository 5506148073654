import instance from "./axios.base";

const getEmployees = async (text) => {
  const res = await instance.get(`/empemployees${text ? `?keyword=${text}` : ''}`);
  return res.data;
};

const getEmployees2 = async (data) => {
  const res = await instance.post(`/empemployees2`, data);
  return res.data;
};

const getEmployee = async (id) => {
  const res = await instance.get(`/employees/${id}?mode=edit`);
  return res.data;
};

const addEmployee = async (data) => {
  await instance.post("/employees", data);
};

const editEmployee = async (data) => {
  await instance.patch("/employees", data);
};

const getPeople = async () => {
  const res = await instance.get("/employees");
  return res.data;
};

export { addEmployee, editEmployee, getEmployees, getEmployees2, getEmployee, getPeople };
