import { combineReducers } from "redux";

import dashboardReducer from "views/dashboard/duck";
import companyInfoReducer from "views/company/duck";
import peopleReducer from "views/people/duck";
import employeesReducer from "views/employees/duck";
import employeeReducer from "views/employeeDetail/duck";
import citiesReducer from "views/pages/cities/duck";
import districtsReducer from "views/districts/duck";
import sectorsReducer from "views/pages/sectors/duck";
import tasksReducer from "views/tasks/duck";
import teamsReducer from "views/teams/duck";
import teammembersReducer from "views/teamMembers/duck";
import departmentsReducer from "views/pages/departments/duck";
import timeattendancelogsReducer from "views/taLogs/duck";
import specialdaysReducer from "views/specialDays/duck";
import empholidaysReducer from "views/empHolidays/duck";
import trainingsReducer from "views/trainings/duck";
import trainingEmployeesReducer from "views/trainingEmployees/duck";
import feedbacksReducer from "views/feedbacks/duck";
import contractsReducer from "views/pages/contracts/duck";
import ordersReducer from "views/pages/orders/duck";
import announcementsReducer from "views/announcements/duck";
import positionsReducer from "views/positions/duck";
import migrationInfoReducer from "views/migrationsHistory/duck";
import migrationInfoItemReducer from "views/migrationInfoItem/duck";
import eventsReducer from "views/events/duck";
import documentFoldersReducer from "views/documentFolders/duck";
import documentFilesReducer from "views/documentFiles/duck";
import educationInfoReducer from "views/educationInfo/duck";
import clothesInfoReducer from "views/clothesInfo/duck";
import experienceInfoReducer from "views/experienceInfo/duck";
import officeSkillInfoReducer from "views/officeSkillInfo/duck";
import officeProgramsReducer from "views/officePrograms/duck";
import langSkillInfoReducer from "views/langSkillInfo/duck";
import talentInfoReducer from "views/talentInfo/duck";
import awardInfoReducer from "views/awardInfo/duck";
import punishmentInfoReducer from "views/punishmentInfo/duck";
import punishmentListReducer from "views/punishmentList/duck";
import emergencyContactInfoReducer from "views/emergencyContactInfo/duck";
import familyInfoReducer from "views/familyInfo/duck";
import degreeInfoReducer from "views/degreeInfo/duck";
import familyMembersReducer from "views/familyMembers/duck";
import horoscopesReducer from "views/horoscopes/duck";
import lunarSignsReducer from "views/lunarSigns/duck";
import nationalitiesReducer from "views/nationalities/duck";
import schedulesReducer from "views/pages/schedules/duck";
import empSchedulesReducer from "views/empSchedules/duck";
import journalsReducer from "views/journals/duck";
import preJournalsReducer from "views/preJournals/duck";
import educationDegreesReducer from "views/educationDegrees/duck";
import languagesReducer from "views/languages/duck";
import insurCompaniesReducer from "views/insurCompanies/duck";
import insurPeopleReducer from "views/insurPeople/duck";
import insurCareersReducer from "views/insurCareers/duck";
import insurSubCareersReducer from "views/insurSubCareers/duck";
import banksReducer from "views/banks/duck";
import raisesReducer from "views/raises/duck";
import raiseTypesReducer from "views/raiseTypes/duck";
import raiseNdTypesReducer from "views/raiseNdTypes/duck";
import deductionsReducer from "views/deductions/duck";
import empRaisesAndDeductionsReducer from "views/empRaisesAndDeductions/duck";
import empRaisesReducer from "views/empRaises/duck";
import systemConfigReducer from "views/systemConfig/duck";
import empDeductionsReducer from "views/empDeductions/duck";
import basicSalariesReducer from "views/basicSalaries/duck";
import userPermissionsReducer from "views/pages/userPermissions/duck";
import permissionsReducer from "views/references/permissions/duck";
import profileReducer from "views/tooltipProfile/duck";
import monthlyAttendanceArchivesReducer from "views/pages/monthlyAttendanceArchives/duck";
import halfMonthlyAttendanceArchivesReducer from "views/pages/halfMonthlyAttendanceArchives/duck";
import prepaymentJournalsReducer from "views/pages/prepaymentJournals/duck";
import resetPasswordReducer from "views/pages/resetPassword/duck";
import aidsInfoReducer from "views/aidsInfo/duck";
import healthInfoReducer from "views/healthInfo/duck";
import NDSH7AReportReducer from "views/pages/reports/NDSH7AReport/duck";
import NDSH7BReportReducer from "views/pages/reports/NDSH7BReport/duck";
import NDSH8ReportReducer from "views/pages/reports/NDSH8Report/duck";
import TT11ReportReducer from "views/pages/reports/TT11Report/duck";
import TT11_1ReportReducer from "views/pages/reports/TT11_1Report/duck";
import authModalReducer from 'components/authModal/authModalReducer'
import permissionModalReducer from 'components/permissionModal/permissionModalReducer';
import relativeInfoReducer from "views/relativeInfo/duck";
import userProfileReducer from "views/pages/login/duck";
import sidebarReducer from "containers/sidebarReducer";

const rootReducer = combineReducers({
  dashboard: dashboardReducer,
  companyInfo: companyInfoReducer,
  people: peopleReducer,
  employees: employeesReducer,
  employee: employeeReducer,
  cities: citiesReducer,
  districts: districtsReducer,
  sectors: sectorsReducer,
  tasks: tasksReducer,
  teams: teamsReducer,
  teammembers: teammembersReducer,
  departments: departmentsReducer,
  timeattendancelogs: timeattendancelogsReducer,
  specialdays: specialdaysReducer,
  empholidays: empholidaysReducer,
  trainings: trainingsReducer,
  trainingEmployees: trainingEmployeesReducer,
  feedbacks: feedbacksReducer,
  contracts: contractsReducer,
  orders: ordersReducer,
  positions: positionsReducer,
  migrationInfo: migrationInfoReducer,
  migrationInfoItem: migrationInfoItemReducer,
  events: eventsReducer,
  documentfolders: documentFoldersReducer,
  documentfiles: documentFilesReducer,
  educationInfo: educationInfoReducer,
  clothesInfo: clothesInfoReducer,
  experienceInfo: experienceInfoReducer,
  officeSkillInfo: officeSkillInfoReducer,
  officePrograms: officeProgramsReducer,
  langSkillInfo: langSkillInfoReducer,
  talentInfo: talentInfoReducer,
  awardInfo: awardInfoReducer,
  punishmentInfo: punishmentInfoReducer,
  punishmentList: punishmentListReducer,
  emergencyContactInfo: emergencyContactInfoReducer,
  familyInfo: familyInfoReducer,
  degreeInfo: degreeInfoReducer,
  familyMembers: familyMembersReducer,
  announcements: announcementsReducer,
  horoscopes: horoscopesReducer,
  lunarSigns: lunarSignsReducer,
  nationalities: nationalitiesReducer,
  schedules: schedulesReducer,
  empSchedules: empSchedulesReducer,
  journals: journalsReducer,
  preJournals: preJournalsReducer,
  educationDegrees: educationDegreesReducer,
  languages: languagesReducer,
  insurCompanies: insurCompaniesReducer,
  insurPeople: insurPeopleReducer,
  insurCareers: insurCareersReducer,
  insurSubCareers: insurSubCareersReducer,
  banks: banksReducer,
  raises: raisesReducer,
  raiseTypes: raiseTypesReducer,
  raiseNdTypes: raiseNdTypesReducer,
  deductions: deductionsReducer,
  empRaisesAndDeductions: empRaisesAndDeductionsReducer,
  empRaises: empRaisesReducer,
  systemConfig: systemConfigReducer,
  empDeductions: empDeductionsReducer,
  basicSalaries: basicSalariesReducer,
  userPermissions: userPermissionsReducer,
  permissions: permissionsReducer,
  profile: profileReducer,
  monthlyAttendanceArchives: monthlyAttendanceArchivesReducer,
  halfMonthlyAttendanceArchives: halfMonthlyAttendanceArchivesReducer,
  prepaymentJournals: prepaymentJournalsReducer,
  resetPassword: resetPasswordReducer,
  aidsInfo: aidsInfoReducer,
  healthInfo: healthInfoReducer,
  NDSH7AReport: NDSH7AReportReducer,
  NDSH7BReport: NDSH7BReportReducer,
  NDSH8Report: NDSH8ReportReducer,
  TT11Report: TT11ReportReducer,
  TT11_1Report: TT11_1ReportReducer,
  authModalReducer: authModalReducer,
  permissionModalReducer: permissionModalReducer,
  relativeInfo: relativeInfoReducer,
  userProfile: userProfileReducer,
  sideBar: sidebarReducer,
});

export default rootReducer;
