import { default as journalsTypes } from "./types";

const {
  ADD_JOURNAL_REQUEST,
  ADD_JOURNAL_SUCCESS,
  ADD_JOURNAL_FAILURE,
  DELETE_JOURNAL_REQUEST,
  DELETE_JOURNAL_SUCCESS,
  DELETE_JOURNAL_FAILURE,
  FETCH_JOURNALS_REQUEST,
  FETCH_JOURNALS_SUCCESS,
  FETCH_JOURNALS_FAILURE,
  FETCH_JOURNAL_REQUEST,
  FETCH_JOURNAL_SUCCESS,
  FETCH_JOURNAL_FAILURE,
  FETCH_STANDARD_TIME_REQUEST,
  FETCH_STANDARD_TIME_SUCCESS,
  FETCH_STANDARD_TIME_FAILURE,
  CALCULATE_JOURNAL_REQUEST,
  CALCULATE_JOURNAL_SUCCESS,
  CALCULATE_JOURNAL_FAILURE,
  FETCH_EMP_LATE_MINUTES_REQUEST,
  FETCH_EMP_LATE_MINUTES_SUCCESS,
  FETCH_EMP_LATE_MINUTES_FAILURE,
  ARCHIVE_JOURNAL_REQUEST,
  ARCHIVE_JOURNAL_SUCCESS,
  ARCHIVE_JOURNAL_FAILURE,
  EDIT_OVERTIME_REQUEST,
  EDIT_OVERTIME_SUCCESS,
  EDIT_OVERTIME_FAILURE,
  CLEAR_JOURNAL,
} = journalsTypes;

const addJournalRequest = () => {
  return {
    type: ADD_JOURNAL_REQUEST,
  };
};

const addJournalSuccess = () => {
  return {
    type: ADD_JOURNAL_SUCCESS,
  };
};

const addJournalError = (error) => {
  return {
    type: ADD_JOURNAL_FAILURE,
    payload: error,
  };
};

const deleteJournalRequest = () => {
  return {
    type: DELETE_JOURNAL_REQUEST,
  };
};

const deleteJournalSuccess = () => {
  return {
    type: DELETE_JOURNAL_SUCCESS,
  };
};

const deleteJournalError = (error) => {
  return {
    type: DELETE_JOURNAL_FAILURE,
    payload: error,
  };
};

const fetchJournalsRequest = () => {
  return {
    type: FETCH_JOURNALS_REQUEST,
  };
};

const fetchJournalsSuccess = (schedules) => {
  return {
    type: FETCH_JOURNALS_SUCCESS,
    payload: schedules,
  };
};

const fetchJournalsError = (error) => {
  return {
    type: FETCH_JOURNALS_FAILURE,
    payload: error,
  };
};

const fetchJournalRequest = () => {
  return {
    type: FETCH_JOURNAL_REQUEST,
  };
};

const fetchJournalSuccess = (data) => {
  return {
    type: FETCH_JOURNAL_SUCCESS,
    payload: data,
  };
};

const fetchJournalError = (error) => {
  return {
    type: FETCH_JOURNAL_FAILURE,
    payload: error,
  };
};

const fetchStandardTimeRequest = () => {
  return {
    type: FETCH_STANDARD_TIME_REQUEST,
  };
};

const fetchStandardTimeSuccess = (data) => {
  return {
    type: FETCH_STANDARD_TIME_SUCCESS,
    payload: data,
  };
};

const fetchStandardTimeError = (error) => {
  return {
    type: FETCH_STANDARD_TIME_FAILURE,
    payload: error,
  };
};

const calculateJournalRequest = () => {
  return {
    type: CALCULATE_JOURNAL_REQUEST,
  };
};

const calculateJournalSuccess = () => {
  return {
    type: CALCULATE_JOURNAL_SUCCESS,
  };
};

const calculateJournalError = (error) => {
  return {
    type: CALCULATE_JOURNAL_FAILURE,
    payload: error,
  };
};

const fetchEmpLateMinutesRequest = () => {
  return {
    type: FETCH_EMP_LATE_MINUTES_REQUEST,
  };
};

const fetchEmpLateMinutesSuccess = (lateMinuteList) => {
  return {
    type: FETCH_EMP_LATE_MINUTES_SUCCESS,
    payload: lateMinuteList,
  };
};

const fetchEmpLateMinutesFailure = (error) => {
  return {
    type: FETCH_EMP_LATE_MINUTES_FAILURE,
    payload: error,
  };
};

const archiveJournalRequest = () => {
  return {
    type: ARCHIVE_JOURNAL_REQUEST,
  };
};

const archiveJournalSuccess = (lateMinuteList) => {
  return {
    type: ARCHIVE_JOURNAL_SUCCESS,
    payload: lateMinuteList,
  };
};

const archiveJournalFailure = (error) => {
  return {
    type: ARCHIVE_JOURNAL_FAILURE,
    payload: error,
  };
};

const editOvertimeRequest = () => {
  return {
    type: EDIT_OVERTIME_REQUEST,
  };
};

const editOvertimeSuccess = () => {
  return {
    type: EDIT_OVERTIME_SUCCESS,
  };
};

const editOvertimeFailure = (error) => {
  return {
    type: EDIT_OVERTIME_FAILURE,
    payload: error,
  };
};

const clearJournal = () => {
  return {
    type: CLEAR_JOURNAL,
  };
};

export default {
  addJournalRequest,
  addJournalSuccess,
  addJournalError,
  deleteJournalRequest,
  deleteJournalSuccess,
  deleteJournalError,
  fetchJournalsRequest,
  fetchJournalsSuccess,
  fetchJournalsError,
  fetchJournalRequest,
  fetchJournalSuccess,
  fetchJournalError,
  fetchStandardTimeRequest,
  fetchStandardTimeSuccess,
  fetchStandardTimeError,
  calculateJournalRequest,
  calculateJournalSuccess,
  calculateJournalError,
  fetchEmpLateMinutesRequest,
  fetchEmpLateMinutesSuccess,
  fetchEmpLateMinutesFailure,
  archiveJournalRequest,
  archiveJournalSuccess,
  archiveJournalFailure,
  editOvertimeRequest,
  editOvertimeSuccess,
  editOvertimeFailure,
  clearJournal,
};
