import instance from "./axios.base";

const getClothesInfo = async (id) => {
  const res = await instance.get(`/empclothsize/${id}`);
  return res.data;
};

const editClothesInfo = async (data) => {
  await instance.put("/empclothsize", data);
};

const deleteClothesInfo = async (id) => {
  await instance.delete(`/empclothsize/${id}`);
};

export { getClothesInfo, editClothesInfo, deleteClothesInfo };