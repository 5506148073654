import { default as journalsTypes } from "./types";

const {
  ADD_JOURNAL_REQUEST,
  ADD_JOURNAL_SUCCESS,
  ADD_JOURNAL_FAILURE,
  EDIT_JOURNAL_REQUEST,
  EDIT_JOURNAL_SUCCESS,
  EDIT_JOURNAL_FAILURE,
  FETCH_JOURNALS_REQUEST,
  FETCH_JOURNALS_SUCCESS,
  FETCH_JOURNALS_FAILURE,
  FETCH_JOURNAL_REQUEST,
  FETCH_JOURNAL_SUCCESS,
  FETCH_JOURNAL_FAILURE,
  FETCH_STANDARD_TIME_REQUEST,
  FETCH_STANDARD_TIME_SUCCESS,
  FETCH_STANDARD_TIME_FAILURE,
  CALCULATE_JOURNAL_REQUEST,
  CALCULATE_JOURNAL_SUCCESS,
  CALCULATE_JOURNAL_FAILURE,
  CLEAR_JOURNAL,
  FETCH_EMP_LATE_MINUTES_REQUEST,
  FETCH_EMP_LATE_MINUTES_SUCCESS,
  FETCH_EMP_LATE_MINUTES_FAILURE,
  ARCHIVE_JOURNAL_REQUEST,
  ARCHIVE_JOURNAL_SUCCESS,
  ARCHIVE_JOURNAL_FAILURE,
  EDIT_OVERTIME_REQUEST,
  EDIT_OVERTIME_SUCCESS,
  EDIT_OVERTIME_FAILURE,
} = journalsTypes;

const initialState = {
  isLoading: false,
  isCalculating: false,
  list: [],
  lateMinuteList: [],
  detail: null,
  standardTime: null,
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_JOURNAL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_JOURNAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case ADD_JOURNAL_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case EDIT_JOURNAL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case EDIT_JOURNAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case EDIT_JOURNAL_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case FETCH_JOURNALS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_JOURNALS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.payload,
        error: "",
      };
    case FETCH_JOURNALS_FAILURE:
      return {
        ...state,
        isLoading: false,
        list: [],
        error: action.payload,
      };
    case FETCH_JOURNAL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_JOURNAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        detail: action.payload,
        error: "",
      };
    case FETCH_JOURNAL_FAILURE:
      return {
        ...state,
        isLoading: false,
        detail: null,
        error: action.payload,
      };
    case FETCH_STANDARD_TIME_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_STANDARD_TIME_SUCCESS:
      return {
        ...state,
        isLoading: false,
        standardTime: action.payload,
        error: "",
      };
    case FETCH_STANDARD_TIME_FAILURE:
      return {
        ...state,
        isLoading: false,
        standardTime: null,
        error: action.payload,
      };
    case CLEAR_JOURNAL:
      return {
        ...state,
        detail: null,
      };
    case CALCULATE_JOURNAL_REQUEST:
      return {
        ...state,
        isCalculating: true,
      };
    case CALCULATE_JOURNAL_SUCCESS:
      return {
        ...state,
        isCalculating: false,
        error: "",
      };
    case CALCULATE_JOURNAL_FAILURE:
      return {
        ...state,
        isCalculating: false,
        error: action.payload,
      };
    case FETCH_EMP_LATE_MINUTES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_EMP_LATE_MINUTES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        lateMinuteList: action.payload,
        error: "",
      };
    case FETCH_EMP_LATE_MINUTES_FAILURE:
      return {
        ...state,
        isLoading: false,
        lateMinuteList: [],
        error: action.payload,
      };
    case ARCHIVE_JOURNAL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ARCHIVE_JOURNAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case ARCHIVE_JOURNAL_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case EDIT_OVERTIME_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case EDIT_OVERTIME_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case EDIT_OVERTIME_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case CLEAR_JOURNAL:
      return {
        ...state,
        isLoading: false,
        detail: null,
        error: "",
      };
    default:
      return state;
  }
};

export default reducer;
