import { toast } from "react-toastify";
import {
  getDocumentFiles,
  createDocumentFile,
  updateDocumentFile,
  removeDocumentFile,
} from "services/documentFile";
import { default as documentfilesActions } from "./actions";

const {
  addDocumentFileRequest,
  addDocumentFileSuccess,
  addDocumentFileError,
  fetchDocumentFilesRequest,
  fetchDocumentFilesSuccess,
  fetchDocumentFilesError,
} = documentfilesActions;

const fetchDocumentFiles = (folderId) => {
  return async (dispatch) => {
    dispatch(fetchDocumentFilesRequest());

    try {
      const documentfiles = await getDocumentFiles(folderId);
      dispatch(fetchDocumentFilesSuccess(documentfiles));
    } catch (err) {
      dispatch(fetchDocumentFilesError(err.message));
    }
  };
};

const addDocumentFile = (data) => {
  return async (dispatch) => {
    dispatch(addDocumentFileRequest());

    try {
      await createDocumentFile(data);
      dispatch(addDocumentFileSuccess());
      toast.success("Амжилттай нэмлээ");
    } catch (err) {
      dispatch(addDocumentFileError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const editDocumentFile = (data) => {
  return async (dispatch) => {
    dispatch(addDocumentFileRequest());

    try {
      await updateDocumentFile(data);
      dispatch(addDocumentFileSuccess());
      toast.success("Амжилттай заслаа");
    } catch (err) {
      dispatch(addDocumentFileError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const deleteDocumentFile = (data) => {
  return async (dispatch) => {
    dispatch(addDocumentFileRequest());

    try {
      await removeDocumentFile(data);
      dispatch(addDocumentFileSuccess());
      toast.success("Амжилттай устгалаа");
    } catch (err) {
      dispatch(addDocumentFileError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

export default {
  fetchDocumentFiles,
  addDocumentFile,
  editDocumentFile,
  deleteDocumentFile,
};
