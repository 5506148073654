import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css"
import "antd/dist/antd.css";
import "scss/style.scss";
import AuthorizationModal from "./authModal/authorizationModal";
import PermissionModal from "./permissionModal/permissionModal";
import { ls } from "utils/ls";

toast.configure({
  position: toast.POSITION.TOP_RIGHT,
});

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const token = ls.get('token')
// Containers
const TheLayout = React.lazy(() => import("../containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("../views/pages/login/Login"));
const ResetPassword = React.lazy(() => import("../views/pages/resetPassword/ResetPassword"));
const Register = React.lazy(() => import("../views/pages/register/Register"));
const Page404 = React.lazy(() => import("../views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("../views/pages/page500/Page500"));
const CompanyRegister = React.lazy(() => import("../views/pages/companyRegister"));

class App extends Component {
  render() {
    return (
      <>
      <ToastContainer transition={Slide}/>
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <AuthorizationModal/>
          <PermissionModal/>
          <Switch>
            <Route exact path="/">
              <Redirect to="/login" />
            </Route>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              path="/resetpassword/:guid"
              name="Reset Password Page"
              render={(props) => <ResetPassword {...props} />}
            />
            <Route
              exact
              path="/resetpassword"
              name="Reset Password Page"
              render={(props) => <ResetPassword {...props} />}
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path="/companyregister"
              name="Company Register Page"
              render={(props) => <CompanyRegister {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={(props) => <TheLayout {...props} />}
            />
            <Route
              path="*"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
      </>
    );
  }
}

export default App;
