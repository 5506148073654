import instance from "./axios.base";

const getOrders = async () => {
  const res = await instance.get("/orders");

  return res.data;
};

const addOrder = async (data) => {
  var formData = new FormData();
  formData.append("orderNo", data.orderNo);
  formData.append("name", data.name);
  formData.append("descr", data.descr);
  formData.append("file", data.file);
  formData.append("orderDate", data.orderDate);

  const res = await instance.post("/orders", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

  return res.data;
};

const editOrder = async (data) => {
  var formData = new FormData();
  formData.append("id", data.id);
  formData.append("orderNo", data.orderNo);
  formData.append("name", data.name);
  formData.append("descr", data.descr);
  formData.append("file", data.file);
  formData.append("orderDate", data.orderDate);

  const res = await instance.put("/orders", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

  return res.data;
};

const deleteOrder = async (id) => {
  const res = await instance.delete(`/orders/${id}`);
  return res.data;
};

export { getOrders, addOrder, editOrder, deleteOrder };
