import instance from "./axios.base";

const getDepartments = async (raw) => {
  const res = await instance.get(`/departments${raw ? `?raw=${raw}` : ''}`);
  let data = res.data;
  data.map((item, idx) => {
    item.children && item.children.map((item, childidx) => {
      item.children && item.children.map((item, idx1) => {
        item.children && item.children.map((item, idx2) => {
          item.children && item.children.map((item, idx3) => {
            item.children && item.children.map((item, idx4) => {
              item.children && item.children.map((item, idx5) => {
                item.children && item.children.map((item, idx6) => {
                  item.children && item.children.map((item, idx7) => {
                    item.children && item.children.map((item, idx8) => {
                      item.children && item.children.map((item, idx9) => {
                        item.children && item.children.map((item, idx10) => {
                          item.key=`${idx}-${childidx}-${idx2}-${idx2}-${idx3}-${idx4}-${idx5}-${idx6}-${idx7}-${idx8}-${idx9}-${idx10}`
                        })
                        item.key=`${idx}-${childidx}-${idx2}-${idx2}-${idx3}-${idx4}-${idx5}-${idx6}-${idx7}-${idx8}-${idx9}`
                      })
                      item.key=`${idx}-${childidx}-${idx2}-${idx2}-${idx3}-${idx4}-${idx5}-${idx6}-${idx7}-${idx8}`
                    })
                    item.key=`${idx}-${childidx}-${idx2}-${idx2}-${idx3}-${idx4}-${idx5}-${idx6}-${idx7}`
                  })
                  item.key=`${idx}-${childidx}-${idx2}-${idx2}-${idx3}-${idx4}-${idx5}-${idx6}`
                })
                item.key=`${idx}-${childidx}-${idx2}-${idx2}-${idx3}-${idx4}-${idx5}`
              })
              item.key=`${idx}-${childidx}-${idx2}-${idx2}-${idx3}-${idx4}`
            })
            item.key=`${idx}-${childidx}-${idx2}-${idx2}-${idx3}`
          })
          item.key=`${idx}-${childidx}-${idx1}-${idx2}`
        })
        item.key=`${idx}-${childidx}-${idx1}`
      })
      item.key=`${idx}-${childidx}`
    })
    item.key = `${idx}`
  })
  return data;
};

const createDepartment = async (data) => {
  const res = await instance.post("/departments", data);

  return res.data;
};

const updateDepartment = async (data) => {
  const res = await instance.put("/departments", data);

  return res.data;
};

const removeDepartment = async (id) => {
  const res = await instance.delete(`/departments/${id}`);

  return res.data;
};

export { getDepartments, createDepartment, updateDepartment, removeDepartment };