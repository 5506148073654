import { toast } from "react-toastify";
import { addEmployee, editEmployee, getEmployees, getEmployees2 } from "services/employee";
import { default as employeesActions } from "./actions";

const {
  addEmployeeRequest,
  addEmployeeSuccess,
  addEmployeeError,
  editEmployeeRequest,
  editEmployeeSuccess,
  editEmployeeError,
  fetchEmployeesRequest,
  fetchEmployeesSuccess,
  fetchEmployeesError,
} = employeesActions;

const add = (data) => {
  return async (dispatch) => {
    dispatch(addEmployeeRequest());

    try {
      await addEmployee(data);
      dispatch(addEmployeeSuccess());
      toast.success("Амжилттай нэмлээ");
    } catch (err) {
      dispatch(addEmployeeError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const edit = (data) => {
  return async (dispatch) => {
    dispatch(editEmployeeRequest());

    try {
      await editEmployee(data);
      dispatch(editEmployeeSuccess());
      toast.success("Амжилттай заслаа");
    } catch (err) {
      dispatch(editEmployeeError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const fetchEmployees = (text) => {
  return async (dispatch) => {
    dispatch(fetchEmployeesRequest());

    try {
      const employees = await getEmployees(text);
      dispatch(fetchEmployeesSuccess(employees));
    } catch (err) {
      dispatch(fetchEmployeesError(err.message));
    }
  };
};

const fetchEmployees2 = (data) => {
  return async (dispatch) => {
    dispatch(fetchEmployeesRequest());

    try {
      const employees = await getEmployees2(data);
      dispatch(fetchEmployeesSuccess(employees));
    } catch (err) {
      dispatch(fetchEmployeesError(err.message));
    }
  };
};

export default {
  add,
  edit,
  fetchEmployees,
  fetchEmployees2
};
