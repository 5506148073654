import { default as trainingsTypes } from "./types";

const {
  ADD_TRAINING_REQUEST,
  ADD_TRAINING_SUCCESS,
  ADD_TRAINING_FAILURE,
  FETCH_TRAININGS_REQUEST,
  FETCH_TRAININGS_SUCCESS,
  FETCH_TRAININGS_FAILURE,
} = trainingsTypes;

const initialState = {
  isLoading: false,
  list: [],
  error: "",    
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TRAINING_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_TRAINING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case ADD_TRAINING_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case FETCH_TRAININGS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_TRAININGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.payload,
        error: "",
      };
    case FETCH_TRAININGS_FAILURE:
      return {
        ...state,
        isLoading: false,
        list: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
