import { default as types } from "./types";

const {
  FETCH_USER_PROFILE_SUCCESS,
  CLEAR_USER_PROFILE_SUCCESS
} = types;

const initialState = {
  isLoading: false,
  userProfile: null,
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userProfile: action.payload,
        error: "",
      };
    case CLEAR_USER_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userProfile: "",
        error: "",
      };
    default:
      return state;
  }
};

export default reducer;
