import { toast } from "react-toastify";
import {
  getEmergencyContactInfo,
  editEmergencyContactInfo,
  deleteEmergencyContactInfo,
} from "services/emergencyContactInfo";
import { default as employeeEmergencyContactInfoActions } from "./actions";

const {
  fetchEmployeeEmergencyContactInfoRequest,
  fetchEmployeeEmergencyContactInfoSuccess,
  fetchEmployeeEmergencyContactInfoError,
  editEmployeeEmergencyContactInfoRequest,
  editEmployeeEmergencyContactInfoSuccess,
  editEmployeeEmergencyContactInfoError,
  deleteEmployeeEmergencyContactInfoRequest,
  deleteEmployeeEmergencyContactInfoSuccess,
  deleteEmployeeEmergencyContactInfoError,
} = employeeEmergencyContactInfoActions;

const fetchEmployeeEmergencyContactInfo = (data) => {
  return async (dispatch) => {
    dispatch(fetchEmployeeEmergencyContactInfoRequest());

    try {
      const employeeEmergencyContactInfo = await getEmergencyContactInfo(
        data
      );
      dispatch(
        fetchEmployeeEmergencyContactInfoSuccess(employeeEmergencyContactInfo)
      );
    } catch (err) {
      dispatch(fetchEmployeeEmergencyContactInfoError(err.message));
    }
  };
};

const editEmployeeEmergencyContactInfo = (data) => {
  return async (dispatch) => {
    dispatch(editEmployeeEmergencyContactInfoRequest());

    try {
      await editEmergencyContactInfo(data);
      dispatch(editEmployeeEmergencyContactInfoSuccess());
      toast.success("Амжилттай заслаа");
    } catch (err) {
      dispatch(editEmployeeEmergencyContactInfoError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const deleteEmployeeEmergencyContactInfo = (id) => {
  return async (dispatch) => {
    dispatch(deleteEmployeeEmergencyContactInfoRequest());

    try {
      await deleteEmergencyContactInfo(id);
      dispatch(deleteEmployeeEmergencyContactInfoSuccess());
      toast.success("Амжилттай устгалаа");
    } catch (err) {
      dispatch(deleteEmployeeEmergencyContactInfoError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

export default {
  fetchEmployeeEmergencyContactInfo,
  editEmployeeEmergencyContactInfo,
  deleteEmployeeEmergencyContactInfo,
};
