import { toast } from "react-toastify";
import {
  getRelativeInfo,
  editRelativeInfo,
  deleteRelativeInfo,
} from "services/relativeInfo";
import { default as employeeRelativeInfoActions } from "./actions";

const {
  fetchEmployeeRelativeInfoRequest,
  fetchEmployeeRelativeInfoSuccess,
  fetchEmployeeRelativeInfoError,
  editEmployeeRelativeInfoRequest,
  editEmployeeRelativeInfoSuccess,
  editEmployeeRelativeInfoError,
  deleteEmployeeRelativeInfoRequest,
  deleteEmployeeRelativeInfoSuccess,
  deleteEmployeeRelativeInfoError,
} = employeeRelativeInfoActions;

const fetchEmployeeRelativeInfo = (data) => {
  return async (dispatch) => {
    dispatch(fetchEmployeeRelativeInfoRequest());
    
    try {
      const employeeRelativeInfo = await getRelativeInfo(data);
      dispatch(fetchEmployeeRelativeInfoSuccess(employeeRelativeInfo));
    } catch (err) {
      dispatch(fetchEmployeeRelativeInfoError(err.message));
    }
  };
};

const editEmployeeRelativeInfo = (id, data) => {
  return async (dispatch) => {
    dispatch(editEmployeeRelativeInfoRequest());

    try {
      await editRelativeInfo(id, data);
      dispatch(editEmployeeRelativeInfoSuccess());
      toast.success("Амжилттай заслаа");
    } catch (err) {
      dispatch(editEmployeeRelativeInfoError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const deleteEmployeeRelativeInfo = (id) => {
  return async (dispatch) => {
    dispatch(deleteEmployeeRelativeInfoRequest());

    try {
      await deleteRelativeInfo(id);
      dispatch(deleteEmployeeRelativeInfoSuccess());
      toast.success("Амжилттай устгалаа");
    } catch (err) {
      dispatch(deleteEmployeeRelativeInfoError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

export default {
  fetchEmployeeRelativeInfo,
  editEmployeeRelativeInfo,
  deleteEmployeeRelativeInfo,
};
