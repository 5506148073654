import { default as employeeClothesInfoTypes } from "./types";

const {
  FETCH_EMPLOYEE_CLOTHES_INFO_REQUEST,
  FETCH_EMPLOYEE_CLOTHES_INFO_SUCCESS,
  FETCH_EMPLOYEE_CLOTHES_INFO_ERROR,
  EDIT_EMPLOYEE_CLOTHES_INFO_REQUEST,
  EDIT_EMPLOYEE_CLOTHES_INFO_SUCCESS,
  EDIT_EMPLOYEE_CLOTHES_INFO_ERROR,
  DELETE_EMPLOYEE_CLOTHES_INFO_REQUEST,
  DELETE_EMPLOYEE_CLOTHES_INFO_SUCCESS,
  DELETE_EMPLOYEE_CLOTHES_INFO_ERROR,
} = employeeClothesInfoTypes;

const fetchEmployeeClothesInfoRequest = () => {
  return {
    type: FETCH_EMPLOYEE_CLOTHES_INFO_REQUEST,
  };
};

const fetchEmployeeClothesInfoSuccess = (employeeClothesInfo) => {
  return {
    type: FETCH_EMPLOYEE_CLOTHES_INFO_SUCCESS,
    payload: employeeClothesInfo,
  };
};

const fetchEmployeeClothesInfoError = (error) => {
  return {
    type: FETCH_EMPLOYEE_CLOTHES_INFO_ERROR,
    payload: error,
  };
};

const editEmployeeClothesInfoRequest = () => {
  return {
    type: EDIT_EMPLOYEE_CLOTHES_INFO_REQUEST,
  };
};

const editEmployeeClothesInfoSuccess = () => {
  return {
    type: EDIT_EMPLOYEE_CLOTHES_INFO_SUCCESS,
  };
};

const editEmployeeClothesInfoError = (error) => {
  return {
    type: EDIT_EMPLOYEE_CLOTHES_INFO_ERROR,
    payload: error,
  };
};

const deleteEmployeeClothesInfoRequest = () => {
  return {
    type: DELETE_EMPLOYEE_CLOTHES_INFO_REQUEST,
  };
};

const deleteEmployeeClothesInfoSuccess = () => {
  return {
    type: DELETE_EMPLOYEE_CLOTHES_INFO_SUCCESS,
  };
};

const deleteEmployeeClothesInfoError = (error) => {
  return {
    type: DELETE_EMPLOYEE_CLOTHES_INFO_ERROR,
    payload: error,
  };
};

export default {
  fetchEmployeeClothesInfoRequest,
  fetchEmployeeClothesInfoSuccess,
  fetchEmployeeClothesInfoError,
  editEmployeeClothesInfoRequest,
  editEmployeeClothesInfoSuccess,
  editEmployeeClothesInfoError,
  deleteEmployeeClothesInfoRequest,
  deleteEmployeeClothesInfoSuccess,
  deleteEmployeeClothesInfoError,
};
