import instance from "./axios.base";
import { startOfMonth } from "date-fns";
import { ls } from "utils/ls";
import moment from 'moment';

class Auth {
  constructor() {
    if (!Auth.instance) {
      this.authenticated = ls.get("access_token") ? true : false;
      this.user = ls.get("user") || null;
      Auth.instance = this;
    }

    return Auth.instance;
  }

  // resetPassword = async (email) => {
  //   await instance.post("api/online/resetpassword", { email });
  // };

  login = async (username, password) => {
    const res = await instance.post("/login", {
      email: username,
      password,
    });
    const { token, role, email, userid, companyid, profile } = res.data;

    const user = {
      role,
      email,
      userid,
      companyid,
      profile,
    };
    

    ls.set("access_token", token);
    ls.set("user", user);
    ls.set("date", moment().startOf('month').format('YYYY-MM-DD hh:mm'));
    ls.set("predate", moment().startOf('month').format('YYYY-MM-DD hh:mm'));

    this.authenticated = true;
    this.user = user;

    return res
  };

  logout = () => {
    this.authenticated = false;
    this.user = null;
    ls.remove("access_token");
    ls.remove("user");
  };

  // register = async (email, firstname, lastname, mobile, password) => {
  //   await instance.post("api/online/register", {
  //     email,
  //     firstname,
  //     lastname,
  //     mobile,
  //     password,
  //   });
  // };

  isAuthenticated = () => {
    return this.authenticated;
  };

  getUser = () => {
    return this.user;
  };
}

export default new Auth();
