import { default as userProfileActions } from "./actions";

const {
  fetchUserProfileSuccess,
  clearUserProfileSuccess
} = userProfileActions;

const fetchUserProfile = (profile) => {
  return async (dispatch) => {
      dispatch(fetchUserProfileSuccess(profile));  
  }
};

const clearUserProfile = () => {
  return async (dispatch) => {
      dispatch(clearUserProfileSuccess());  
  }
};

export default { fetchUserProfile, clearUserProfile };
