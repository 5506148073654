import React, { useState } from 'react'
import { CAlert, CButton, CModal, CModalBody } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { BsShieldLock } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux'
import store from '../../store'

function PermissionModal(props) {
    const history = useHistory();
    return (
        <div className='animated fadeIn'>
            <CModal  show={props.toggle} onClose={()=> store.dispatch({type:'RENDER_PERMISSION_MODAL', toggle: false})}>
                { props.toggle ?
                    <CAlert color="warning" className='mb-0'>
                        <div style={{display:'flex', flexDirection:'column', alignItems:'center', paddingTop:'20px'}}>
                            <BsShieldLock size={80} style={{color:'#f8bc86'}}/>
                            {/* <CIcon width='50px' style={{color:'#f8bc86'}} name='cil-warning'/> */}
                            <h3 className='mt-3' style={{color:'#5e5335'}} >Таны эрх хүрэхгүй байна</h3>
                        </div>
                        
                        <hr />
                        <p className="text-center" style={{fontSize:'18px'}} >
                            Та уг мэдээлэлд хандахыг хүсвэл хүний нөөцийн ажилтанд хандана уу.
                        </p>
                    </CAlert> : 
                    null
                }
            </CModal>
        </div>
    )
}

const mapStateToProps = (state) => {
    return state.permissionModalReducer
}

export default connect(mapStateToProps)(PermissionModal)
