import { default as employeeEmergencyContactInfoTypes } from "./types";

const {
  FETCH_EMPLOYEE_EMERGENCY_CONTACT_INFO_REQUEST,
  FETCH_EMPLOYEE_EMERGENCY_CONTACT_INFO_SUCCESS,
  FETCH_EMPLOYEE_EMERGENCY_CONTACT_INFO_FAILURE,
  EDIT_EMPLOYEE_EMERGENCY_CONTACT_INFO_REQUEST,
  EDIT_EMPLOYEE_EMERGENCY_CONTACT_INFO_SUCCESS,
  EDIT_EMPLOYEE_EMERGENCY_CONTACT_INFO_FAILURE,
  DELETE_EMPLOYEE_EMERGENCY_CONTACT_INFO_REQUEST,
  DELETE_EMPLOYEE_EMERGENCY_CONTACT_INFO_SUCCESS,
  DELETE_EMPLOYEE_EMERGENCY_CONTACT_INFO_FAILURE,
} = employeeEmergencyContactInfoTypes;

const fetchEmployeeEmergencyContactInfoRequest = () => {
  return {
    type: FETCH_EMPLOYEE_EMERGENCY_CONTACT_INFO_REQUEST,
  };
};

const fetchEmployeeEmergencyContactInfoSuccess = (
  employeeEmergencyContactInfo
) => {
  return {
    type: FETCH_EMPLOYEE_EMERGENCY_CONTACT_INFO_SUCCESS,
    payload: employeeEmergencyContactInfo,
  };
};

const fetchEmployeeEmergencyContactInfoError = (error) => {
  return {
    type: FETCH_EMPLOYEE_EMERGENCY_CONTACT_INFO_FAILURE,
    payload: error,
  };
};

const editEmployeeEmergencyContactInfoRequest = () => {
  return {
    type: EDIT_EMPLOYEE_EMERGENCY_CONTACT_INFO_REQUEST,
  };
};

const editEmployeeEmergencyContactInfoSuccess = () => {
  return {
    type: EDIT_EMPLOYEE_EMERGENCY_CONTACT_INFO_SUCCESS,
  };
};

const editEmployeeEmergencyContactInfoError = (error) => {
  return {
    type: EDIT_EMPLOYEE_EMERGENCY_CONTACT_INFO_FAILURE,
    payload: error,
  };
};

const deleteEmployeeEmergencyContactInfoRequest = () => {
  return {
    type: DELETE_EMPLOYEE_EMERGENCY_CONTACT_INFO_REQUEST,
  };
};

const deleteEmployeeEmergencyContactInfoSuccess = () => {
  return {
    type: DELETE_EMPLOYEE_EMERGENCY_CONTACT_INFO_SUCCESS,
  };
};

const deleteEmployeeEmergencyContactInfoError = (error) => {
  return {
    type: DELETE_EMPLOYEE_EMERGENCY_CONTACT_INFO_FAILURE,
    payload: error,
  };
};

export default {
  fetchEmployeeEmergencyContactInfoRequest,
  fetchEmployeeEmergencyContactInfoSuccess,
  fetchEmployeeEmergencyContactInfoError,
  editEmployeeEmergencyContactInfoRequest,
  editEmployeeEmergencyContactInfoSuccess,
  editEmployeeEmergencyContactInfoError,
  deleteEmployeeEmergencyContactInfoRequest,
  deleteEmployeeEmergencyContactInfoSuccess,
  deleteEmployeeEmergencyContactInfoError,
};
