import instance from "./axios.base";

const getRelativeInfo = async (id) => {
  const res = await instance.get(`/emprelative/${id}`);
  return res.data;
};

const editRelativeInfo = async (id, data) => {
  await instance.put(`/emprelative/${id}`, data);
};

const deleteRelativeInfo = async (id) => {
  await instance.delete(`/emprelative/${id}`);
};

export { getRelativeInfo, editRelativeInfo, deleteRelativeInfo };