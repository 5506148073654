import { default as employeeRelativeInfoTypes } from "./types";

const {
  FETCH_EMPLOYEE_RELATIVE_INFO_REQUEST,
  FETCH_EMPLOYEE_RELATIVE_INFO_SUCCESS,
  FETCH_EMPLOYEE_RELATIVE_INFO_ERROR,
  EDIT_EMPLOYEE_RELATIVE_INFO_REQUEST,
  EDIT_EMPLOYEE_RELATIVE_INFO_SUCCESS,
  EDIT_EMPLOYEE_RELATIVE_INFO_ERROR,
  DELETE_EMPLOYEE_RELATIVE_INFO_REQUEST,
  DELETE_EMPLOYEE_RELATIVE_INFO_SUCCESS,
  DELETE_EMPLOYEE_RELATIVE_INFO_ERROR,
} = employeeRelativeInfoTypes;

const fetchEmployeeRelativeInfoRequest = () => {
  return {
    type: FETCH_EMPLOYEE_RELATIVE_INFO_REQUEST,
  };
};

const fetchEmployeeRelativeInfoSuccess = (employeeRelativeInfo) => {
  return {
    type: FETCH_EMPLOYEE_RELATIVE_INFO_SUCCESS,
    payload: employeeRelativeInfo,
  };
};

const fetchEmployeeRelativeInfoError = (error) => {
  return {
    type: FETCH_EMPLOYEE_RELATIVE_INFO_ERROR,
    payload: error,
  };
};

const editEmployeeRelativeInfoRequest = () => {
  return {
    type: EDIT_EMPLOYEE_RELATIVE_INFO_REQUEST,
  };
};

const editEmployeeRelativeInfoSuccess = () => {
  return {
    type: EDIT_EMPLOYEE_RELATIVE_INFO_SUCCESS,
  };
};

const editEmployeeRelativeInfoError = (error) => {
  return {
    type: EDIT_EMPLOYEE_RELATIVE_INFO_ERROR,
    payload: error,
  };
};

const deleteEmployeeRelativeInfoRequest = () => {
  return {
    type: DELETE_EMPLOYEE_RELATIVE_INFO_REQUEST,
  };
};

const deleteEmployeeRelativeInfoSuccess = () => {
  return {
    type: DELETE_EMPLOYEE_RELATIVE_INFO_SUCCESS,
  };
};

const deleteEmployeeRelativeInfoError = (error) => {
  return {
    type: DELETE_EMPLOYEE_RELATIVE_INFO_ERROR,
    payload: error,
  };
};

export default {
  fetchEmployeeRelativeInfoRequest,
  fetchEmployeeRelativeInfoSuccess,
  fetchEmployeeRelativeInfoError,
  editEmployeeRelativeInfoRequest,
  editEmployeeRelativeInfoSuccess,
  editEmployeeRelativeInfoError,
  deleteEmployeeRelativeInfoRequest,
  deleteEmployeeRelativeInfoSuccess,
  deleteEmployeeRelativeInfoError,
};
