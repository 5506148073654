import instance from "./axios.base";

const getPositions = async (id) => {
  const res = await instance.get(`/positions${id ? `?departmentId=${id}` : ''}`);
  let data = res.data;
  data.map((item, idx) => {
    item.children && item.children.map((item, childidx) => {
      item.children && item.children.map((item, idx1) => {
        item.children && item.children.map((item, idx2) => {
          item.key=Number(`${idx+1}${childidx+1}${idx1+1}${idx2+1}`)
        })
        item.key=Number(`${idx+1}${childidx+1}${idx1+1}`)
      })
      item.key=Number(`${idx+1}${childidx+1}`)
    })
    item.key = Number(`${idx+1}`)
  })
  return data;
};

const createPosition = async (data) => {
  var formData = new FormData();
  formData.append("parentId", data.parentId);
  formData.append("name", data.name);
  formData.append("descr", data.descr);
  formData.append("classification", data.classification);
  formData.append("positionrank", data.positionrank);
  formData.append("decision", data.decision);
  formData.append("file", data.file);
  formData.append("decisiondate", data.decisiondate);
  formData.append("departmentId", data.departmentId);
  formData.append("orderIndex", data.orderIndex);
  formData.append("workCondition", data.workCondition);
  const res = await instance.post("/positions", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

  return res.data;
};

const updatePosition = async (data) => {
  var formData = new FormData();
  formData.append("id", data.id);
  formData.append("parentId", data.parentId);
  formData.append("name", data.name);
  formData.append("descr", data.descr);
  formData.append("classification", data.classification);
  formData.append("positionrank", data.positionrank);
  formData.append("decision", data.decision);
  formData.append("file", data.file);
  formData.append("decisiondate", data.decisiondate);
  formData.append("departmentId", data.departmentId);
  formData.append("orderIndex", data.orderIndex);
  formData.append("workCondition", data.workCondition);
  const res = await instance.put("/positions", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

  return res.data;
};

const removePosition = async (data) => {
  const res = await instance.delete(`/positions/${data.id}`);

  return res.data;
};

export { getPositions, createPosition, updatePosition, removePosition };
