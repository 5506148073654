import ReactDOM from 'react-dom'
import React from 'react';
import axios from "axios";
import { ls } from "utils/ls";
import { render } from 'enzyme';
import store from './../store';


const config = {
  baseURL: process.env.REACT_APP_API_URL,
  responseType: "json",
};

const instance = axios.create(config);

instance.interceptors.request.use(
  function (config) {
    const token = ls.get("access_token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);

instance.interceptors.response.use(function (response) {
  store.dispatch({type: 'RENDER_PERMISSION_MODAL', toggle: false})
  store.dispatch({type: 'RENDER_AUTH_MODAL', toggle: false})
  return response;
}, function (error) { 
  if(error.response.status === 401){
    let errorData = Object.keys(error.response.data)
    if(errorData.length > 0){
      store.dispatch({type: 'RENDER_AUTH_MODAL', toggle: true, information: error.response.data.data})
    }
  }
  if(error.response.status === 403){
    store.dispatch({type: 'RENDER_PERMISSION_MODAL', toggle: true})
  }
  return Promise.reject(error);
});

export default instance;
