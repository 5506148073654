import { default as types } from "./types";

const {
  FETCH_USER_PROFILE_SUCCESS,
  CLEAR_USER_PROFILE_SUCCESS,
} = types;


const fetchUserProfileSuccess = (profile) => {
  return {
    type: FETCH_USER_PROFILE_SUCCESS,
    payload: profile,
  };
};

const clearUserProfileSuccess = () => {
  return {
    type: CLEAR_USER_PROFILE_SUCCESS,
  };
};

export default {fetchUserProfileSuccess, clearUserProfileSuccess};
