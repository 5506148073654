
const initialState = {
    toggle: false,
    information: ''
}

const reducer = (state = initialState, action) => {
  if (action.type === 'RENDER_AUTH_MODAL') {
      return {
        ...state,
        toggle: action.toggle,
        information: action.information
      }
  }
  else
   return state
};

export default reducer;
 