import React, { useState } from 'react'
import { CAlert, CButton, CModal, CModalBody } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import Auth from 'services/Auth'
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux'
import store from '../../store'

function AuthorizationModal(props) {
    const [visible, setVisible] = useState(false)
    const history = useHistory();
    return (
        <div className='animated fadeIn'>
            <CModal closeOnBackdrop show={props.toggle} onClose={()=> store.dispatch({type:'RENDER_AUTH_MODAL', toggle: false})}>
                { props.toggle ?
                    <CAlert color="warning" className='mb-0'>
                        <div style={{display:'flex', flexDirection:'column', alignItems:'center', paddingTop:'20px'}}>
                            <CIcon width='50px' style={{color:'#f8bc86'}} name='cil-warning'/>
                            <h3 className='mt-3' style={{color:'#5e5335'}} >Таны хаягаар давхар нэвтэрсэн байна</h3>
                        </div>
                        <ul className='mt-2 ml-3'>
                            <li><span className='font-weight-bold'>IP:&nbsp;</span> {props.information && props.information.ipaddress}</li>
                            <li><span className='font-weight-bold'>Хөтөч:&nbsp;</span> {props.information && props.information.browser}</li>
                            <li><span className='font-weight-bold'>Үйлдлийн систем:&nbsp;</span> {props.information && props.information.osname}</li>
                            <li><span className='font-weight-bold'>Улс:&nbsp;</span> { props.information && props.information.country}</li>
                            <li><span className='font-weight-bold'>Цагийн бүс:&nbsp;</span> { props.information && props.information.timezone}</li>
                            <li><span className='font-weight-bold'>Нэвтэрсэн огноо:&nbsp;</span> {props.information && props.information.createdAt}</li>
                        </ul>
                        <hr />
                        <p className="text-center" >
                        <CButton color='info' onClick={async ()=> { 
                            await Auth.logout(); 
                            store.dispatch({type:'RENDER_AUTH_MODAL', toggle: false})
                            history.push('/login')
                            }}
                            >
                                Дахин нэвтрэх
                            </CButton>
                        </p>
                    </CAlert> : 
                    null
                }
            </CModal>
        </div>
    )
}

const mapStateToProps = (state) => {
    return state.authModalReducer
}

export default connect(mapStateToProps)(AuthorizationModal)
