import { toast } from "react-toastify";
import {
  getClothesInfo,
  editClothesInfo,
  deleteClothesInfo,
} from "services/clothesInfo";
import { default as employeeClothesInfoActions } from "./actions";

const {
  fetchEmployeeClothesInfoRequest,
  fetchEmployeeClothesInfoSuccess,
  fetchEmployeeClothesInfoError,
  editEmployeeClothesInfoRequest,
  editEmployeeClothesInfoSuccess,
  editEmployeeClothesInfoError,
  deleteEmployeeClothesInfoRequest,
  deleteEmployeeClothesInfoSuccess,
  deleteEmployeeClothesInfoError,
} = employeeClothesInfoActions;

const fetchEmployeeClothesInfo = (data) => {
  return async (dispatch) => {
    dispatch(fetchEmployeeClothesInfoRequest());

    try {
      const employeeClothesInfo = await getClothesInfo(data);
      dispatch(fetchEmployeeClothesInfoSuccess(employeeClothesInfo));
    } catch (err) {
      dispatch(fetchEmployeeClothesInfoError(err.message));
    }
  };
};

const editEmployeeClothesInfo = (data) => {
  return async (dispatch) => {
    dispatch(editEmployeeClothesInfoRequest());

    try {
      await editClothesInfo(data);
      dispatch(editEmployeeClothesInfoSuccess());
      toast.success("Амжилттай заслаа");
    } catch (err) {
      dispatch(editEmployeeClothesInfoError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const deleteEmployeeClothesInfo = (id) => {
  return async (dispatch) => {
    dispatch(deleteEmployeeClothesInfoRequest());

    try {
      await deleteClothesInfo(id);
      dispatch(deleteEmployeeClothesInfoSuccess());
      toast.success("Амжилттай устгалаа");
    } catch (err) {
      dispatch(deleteEmployeeClothesInfoError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

export default {
  fetchEmployeeClothesInfo,
  editEmployeeClothesInfo,
  deleteEmployeeClothesInfo,
};
